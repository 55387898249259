<template>
  <div>
    <OrderForSelection v-if="'PAR' == partner_type"></OrderForSelection>
    <div style="position: relative">
      <v-overlay v-if="show_overlay" style="margin: -15px" absolute></v-overlay>
      <v-card>
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>
            {{ $t("Private availability check") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <gfo-checker
            v-if="script_loaded"
            data-checker_key="private_listed"
            data-check_by="address,oto_id"
            :data-api_url="api_url"
            :data-api_token="api_token"
            :data-address_api_token="$config.addressBackendToken"
            :data-order_page="order_page"
            :data-theme="theme"
            data-netz_display="available"
            data-order_form_version="2"
          >
          </gfo-checker>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import OrderForSelection from "@/components/orders/gfoOrder/OrderForSelection.vue";

export default {
  props: ["netz"],
  name: "ResellerGfoChecker",
  components: { OrderForSelection },
  data: function () {
    return {
      script_loaded: false,
    };
  },
  computed: {
    show_overlay: function () {
      return (
        this.loading ||
        ("PAR" == this.partner_type && "" == this.order_for_number)
      );
    },
    partner_type: function () {
      return this.$store.state.session.partnerType;
    },
    order_for_number: function () {
      return this.$store.state.gfoWebcomponent.order_for.order_for_number;
    },
    api_token: function () {
      if (
        "END" == this.partner_type ||
        ("PAR" == this.partner_type && "0" != this.order_for_number)
      ) {
        // Endkunde oder Partner der für Endkunde bestellt
        return this.$config.gfoApiTokenEnd;
      } else if ("WHO" == this.partner_type) {
        return this.$config.gfoApiTokenWho;
      } else {
        return this.$config.gfoApiTokenPar;
      }
    },
    api_url: function () {
      return this.$config.gfoApiUrl;
    },
    order_page: function () {
      let route = this.$router.resolve({ name: "gfo-internet-checkout" });
      return route.href;
    },
    theme: function () {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  created: function () {
    // beforeCreate funktioniert nicht - wird nicht geladen
    let that = this;
    let els = document.getElementsByTagName("script");
    let found = false;
    let src = this.$config.gfoCheckerSrc;
    for (var i = 0; i < els.length; i++) {
      if (els[i].src.includes("lic_gfo_checker_2.js")) {
        found = true;
      }
    }
    if (!found) {
      let el = document.createElement("SCRIPT");
      el.src = src;
      el.type="module"; // für checker v2 benötigt
      el.crossorigin = true; // für checker v2 benötigt
      el.onload = function () {
        that.script_loaded = true;
      };
      document.body.append(el);
    } else {
      that.script_loaded = true;
    }
  },
};
</script>

<i18n>
{
  "en": {
    "Private availability check": "Availablility check"
  },
  "de": {
    "Private availability check": "Verfügbarkeitsprüfung"
  }
}
</i18n>
